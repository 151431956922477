// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;500);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YvhQ7KFSOQoHTkd8mfLV{position:relative;width:476px;margin:auto auto}.TNPXJL9JNqT7G_JCqLqM{display:flex;width:100%;flex-direction:column;align-items:center;row-gap:28px;padding:0px 14px 0px 4px}.TNPXJL9JNqT7G_JCqLqM .Zcz_hKGAIdbtCL0M67uF{position:absolute;top:22px;left:40px;font-size:24px !important}.TNPXJL9JNqT7G_JCqLqM .bklOJSx52cXlcc138t3s{display:flex;flex-direction:column;row-gap:16px;margin-top:38px}.TNPXJL9JNqT7G_JCqLqM .bklOJSx52cXlcc138t3s .X9A6Oc1vcgIUKqEyog9p{font-size:16px !important;font-weight:600}.TNPXJL9JNqT7G_JCqLqM .bklOJSx52cXlcc138t3s .X9A6Oc1vcgIUKqEyog9p a{color:#774dee;text-decoration:none;font-size:16px !important}.TNPXJL9JNqT7G_JCqLqM .bklOJSx52cXlcc138t3s .XI9F26_MSByF8l6swWyA{color:#dbdbdb}.TNPXJL9JNqT7G_JCqLqM .bklOJSx52cXlcc138t3s .XI9F26_MSByF8l6swWyA *{font-size:14px;font-weight:500}.TNPXJL9JNqT7G_JCqLqM .bklOJSx52cXlcc138t3s .XI9F26_MSByF8l6swWyA a{color:#774dee;text-decoration:none}.TNPXJL9JNqT7G_JCqLqM .Q7OUf5nr5cEpjDkIb39v{cursor:pointer;background-color:rgba(0,0,0,0);border:none;cursor:pointer;transition:filter .2s,transform .2s,box-shadow .2s}.TNPXJL9JNqT7G_JCqLqM .Q7OUf5nr5cEpjDkIb39v:hover{filter:brightness(85%);box-shadow:0 4px 8px rgba(0,0,0,.1)}.TNPXJL9JNqT7G_JCqLqM .Q7OUf5nr5cEpjDkIb39v:focus{outline:none}.TNPXJL9JNqT7G_JCqLqM .Q7OUf5nr5cEpjDkIb39v:active{filter:brightness(70%);box-shadow:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `YvhQ7KFSOQoHTkd8mfLV`,
	"contentWrapper": `TNPXJL9JNqT7G_JCqLqM`,
	"title": `Zcz_hKGAIdbtCL0M67uF`,
	"content": `bklOJSx52cXlcc138t3s`,
	"p1": `X9A6Oc1vcgIUKqEyog9p`,
	"p2": `XI9F26_MSByF8l6swWyA`,
	"signInButton": `Q7OUf5nr5cEpjDkIb39v`
};
export default ___CSS_LOADER_EXPORT___;
